//
// toastr.scss
//


/* =============
   Notification
============= */
#toast-container {
    > div {
        box-shadow: $box-shadow;
        opacity: 1;
    }
    >:hover {
        box-shadow: $box-shadow;
        opacity: 0.9;
    }
    
}

@mixin toast-variant($bg) {
    border: 2px solid $bg !important;
    background-color: rgba($bg, 0.8) !important;
}

@each $color, $value in $theme-colors {
    .toast-#{$color} {
      @include toast-variant($value);
    }
}



//Toasts customization

.toast-container {
    z-index: 1002;
}

.toast-header {
    color: #fff;
    background-color: transparent;
    border-bottom: none;
}

.toast-header .btn-close {
    opacity: 0.8 !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") center / 0.8rem auto no-repeat;
}

.toast {
    border-radius: 0.5rem !important;
}

.toast-link {
    color: #fff;
    text-decoration: underline;
}

.toast-link:hover {
    color: #fff;
    opacity: 0.9;
}

.bg-success {
    background-color: #1FD0BF !important;
}

.bg-warning {
    background-color: #e0aa46 !important;
}

.bg-danger {
    background-color: #e3225a !important;
}
