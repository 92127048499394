//
// quilljs-editor.scss
//

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

.ql-container {
    font-family: $font-family-base;
    border-bottom-left-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;

    &.ql-snow {
        border-color: $input-border-color;
    }
}

.ql-bubble {
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
}

.ql-toolbar {
    font-family: $font-family-base !important;
    border-top-left-radius: $input-border-radius;
    border-top-right-radius: $input-border-radius;
    span {
        outline: none !important;
        color: #{map-get($grays, "700")};

        &:hover {
            color: $primary !important;
        }
    }

    &.ql-snow {
        border-color: $input-border-color;

        .ql-picker.ql-expanded {
            .ql-picker-label {
                border-color: transparent;
            }
        }
        .ql-formats {
            margin-right: 11px;
            margin-left: 0;
        }
    }
}

.ql-snow {
    .ql-stroke,
    .ql-script,
    .ql-strike svg {
        stroke: #{map-get($grays, "700")};
    }
    .ql-fill {
        fill: #{map-get($grays, "700")};
    }
}

.ql-snow {
    .ql-picker-options {
        background-color: $dropdown-bg;
        border-color: $dropdown-border-color !important;
    }
}

.ql-editor {
    text-align: left;
    color: #{map-get($grays, "600")};
    font-weight: $font-weight-normal;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute;
    margin-top: -9px;
    right: 0;
    left: auto;
    top: 50%;
    width: 18px;
}

.ql-editor {
    ol,
    ul {
        li:not(.ql-direction-rtl) {
            padding-left: 1.5em;
            padding-right: 0;
        }
    }
    li:not(.ql-direction-rtl) {
        &:before {
            margin-left: -1.5em;
            margin-right: 0.3em;
            text-align: right;
        }
    }
}

.ql-editor {
    ol,
    ul {
        padding-left: 1.5em;
        padding-right: 0;
    }
}

// dark mode
body[data-layout-color="dark"] {
    .ql-toolbar {
        span {
            color: #{map-get($dark-grays, "700")};
        }
    }
    .ql-snow {
        .ql-stroke,
        .ql-script,
        .ql-strike svg {
            stroke: #{map-get($dark-grays, "700")};
        }
        .ql-fill {
            fill: #{map-get($dark-grays, "700")};
        }
    }
    .ql-editor {
        color: #{map-get($dark-grays, "600")};
    }
}
