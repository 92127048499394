//
// popover.scss
//

.popover-header {
    margin-top: 0;
}

.error .popover-body {
  font-size: smaller !important;
}

.popover-body, .popover-arrow {
  display: none !important;
}
