/*
Template Name: Adminto - Responsive Bootstrap 5 Admin Dashboard
Version: 5.0.0
Author: CoderThemes
Email: support@coderthemes.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";
