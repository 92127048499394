//
// modal.scss
//

// Title text within header
.modal-title {
    margin-top: 0;
}

// Modal full width
.modal-full-width {
    width: 95%;
    max-width: none;
}

// Modal Positions
.modal-top {
    margin: 0 auto;
}

// Right Modal
.modal-right {
    position: absolute;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    margin: 0;
    background-color: $modal-content-bg;
    align-content: center;
    transform: translate(25%, 0) !important;

    button.btn-close {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1;
    }
}

.modal {
    &.show {
        .modal-right,
        .modal-left {
            transform: translate(0, 0) !important;
        }
    }
}

// Bottom modal
.modal-bottom {
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    align-content: center;
}

// Colored modal header
.modal-colored-header {
    color: $white;
    border-radius: 0;

    .btn-close {
        color: $white !important;
    }
}

.modal-filled {
    color: $white;

    .modal-content {
        background-color: inherit;
    }
}

.modal.bg-primary {
  background-color: var(--ct-primary) !important;
  opacity: 1 !important;
}

/*
@media screen and (min-width: 1366px) {
  .full-screen-modal .modal-dialog {
    margin: 0 !important;
  }
}

@media screen and (min-width: 1024px) {
  .full-screen-modal .modal-dialog {
    max-width: 120em !important;
    margin: 0 !important;
  }

  .full-screen-modal .modal-content {
    height: auto !important;
  }
}
*/

@media screen and (max-width: 768px) {
    .modal-dialog.modal-lg {
        top: 10%;
    }
}
