//
// jstree.scss
//

@import "~@circlon/angular-tree-component/css/angular-tree-component.css";

.jstree-default {
    .jstree-node,
    .jstree-icon {
        background-image: url("../../../images/icons/jstree.png");
    }
    .jstree-node {
        background-position: -292px -4px;
        background-repeat: repeat-y;
    }

    .jstree-themeicon-custom {
        background-color: transparent;
        background-image: none;
        background-position: 0 0;
    }

    .jstree-anchor {
        line-height: 28px;
        height: 28px;
    }

    & > .jstree-container-ul .jstree-loading > .jstree-ocl {
        background: url("../../../images/icons/loading.gif") center center no-repeat;
    }

    .jstree-icon:empty {
        width: 24px;
        height: 28px;
        line-height: 28px;
        font-size: 15px;
    }

    .jstree-clicked,
    .jstree-wholerow-clicked {
        background: rgba($primary, 0.1);
        box-shadow: none;
    }

    .jstree-hovered,
    .jstree-wholerow-hovered {
        background: rgba($primary, 0.15);
        box-shadow: none;
    }

    .jstree-last {
        background: transparent;
    }

    .jstree-wholerow {
        height: 28px;
    }
}

// custom css
// .toggle-children-wrapper-expanded .toggle-children {
//     transform: rotate(45deg);
// }

.reverse tree-node-expander {
    order: 0;
}
.reverse tree-node-checkbox {
    order: 1;
}
.reverse .node-content-wrapper {
    order: 2;
}
