//
// reboot.scss
//

body {
    padding-right: 0px !important;
    padding-left: 0px !important;
    &.loading {
        visibility: hidden;
        opacity: 0;
    }
}

label {
    font-weight: $label-font-weight;
}


pre{
  background-color: #{lighten(map-get($grays, "200"), 2%)};
  border: #{$border-width} solid #{map-get($grays, "300")};
  padding: 7px;
}

button:focus {
    outline: none;
}

 // blockquote

.blockquote{
    padding: 10px 20px;
    margin-bottom: $blockquote-margin-y;
    border-left: 4px solid #{map-get($grays, "300")};
    @include font-size($blockquote-footer-font-size);

    &.blockquote-reverse{
      border-left: 0;
      border-right: 4px solid #{map-get($grays, "300")};
      text-align: right;
    }
  }

.blockquote-footer {
    margin-top: $blockquote-margin-y;
    margin-bottom: $blockquote-margin-y;
    @include font-size($blockquote-footer-font-size);
    color: $blockquote-footer-color;

    &::before {
      content: "\2014\00A0"; // em dash, nbsp
    }
  }


  // dark mode
body[data-layout-color="dark"] {
  pre{
    background-color: #{lighten(map-get($dark-grays, "200"), 2%)};
    border: #{$border-width} solid #{map-get($dark-grays, "300")};
  }
  .blockquote{
    border-left: 4px solid #{map-get($dark-grays, "300")};

    &.blockquote-reverse{
      border-right: 4px solid #{map-get($dark-grays, "300")};
    }
  }
}

[type=search], select:focus-visible {
  outline-offset: -2px;
  outline-color: transparent !important;
  border: 1px solid #ddd !important;
}
